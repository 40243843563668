import { useState } from 'react';
import { send } from 'emailjs-com';
import './modal.css'

const Modal = (props) => {
  const {closeFunction} = props
  const [toSend, setToSend] = useState({
    from_name: '',
    message: '',
    suggested_game: '',
  });
  const [formState, setFormState] = useState('')


  const onSubmit = (e) => {
    e.preventDefault();
    if(toSend.message.length > 0){
      setFormState('sending');
      send(
        'service_dt5is3i',
        'template_q0zehsi',
        toSend,
        'nDkstveBf8cwsG75v'
      )
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setFormState('success')
      })
      .catch((err) => {
        console.log('FAILED...', err);
        setFormState('error')
      });
    }
  }

  const handleClick = () =>{
    closeFunction(false)
  }

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };
  return(
    <div className="modal">
      <b>Suggest a Game</b>
      <b className="close-button" onClick={handleClick}>x</b>
      <form onSubmit={onSubmit}>
            <input
              type='text'
              name='from_name'
              placeholder='Your name (not required) and pronouns'
              value={toSend.from_name}
              onChange={handleChange}
            />
            <input
              type='text'
              name='suggested_game'
              placeholder='The game you want us to play'
              value={toSend.suggested_game}
              onChange={handleChange}
            />
            <textarea
              type='text'
              name='message'
              placeholder='Any feelings you have about the game that you want us to include (also not required)'
              value={toSend.message}
              onChange={handleChange}
            />
            {formState === '' && (
            <button className="submit-button" type='submit'>
              <span>Submit</span>
              {/* {formState === 'sending' && (
                <Spinner/>
              )} */}
              </button>
            )}
            {formState === 'success' &&(
              <b>Message received, thanks for sending one in!</b>
            )}
            {formState === 'error' &&(
              <b>Looks like something, went wrong. Maybe try a refresh and try again?</b>
            )}
          </form>
    </div>
  )
}

export default Modal