const gameList = [{
  date:"June 24th",
  link:"https://seaexcursion.itch.io/exquisite-biome",
  name:"Exquisite Biome",
  type:"game",
},
{
  date:"July 1st",
  link:"https://store.steampowered.com/app/683320/GRIS/",
  name:"Gris",
  type:"game",
},
{
  date:"July 8th",
  link:"https://paranoidhawk.itch.io/lookouts",
  name:"Lookouts",
  type:"ttrpg",
},
{
  date:"July 15th",
  link:"https://alwayscheckers.itch.io/notorious",
  name:"Notorious",
  type:"game",
},
{
  date:"July 22nd",
  link:"https://store.steampowered.com/app/501300/What_Remains_of_Edith_Finch/",
  name:"What Remains of Edith Finch",
  type:"game",
},
{
  date:"July 29th",
  link:"https://store.steampowered.com/app/2533960/SUMMERHOUSE/",
  name:"SUMMERHOUSE",
  type:"game",
},
{
  date:"August 5th",
  link:"https://store.steampowered.com/app/1281270/Fatum_Betula/",
  name:"Fatum Betula",
  type:"game",
},
{
  date:"August 12th",
  link:"https://store.steampowered.com/app/1854430/How_Fish_Is_Made/",
  name:"How Fish Is Made",
  type:"game",
},
{
  date:"August 19th",
  link:"https://store.steampowered.com/app/2570630/20_Small_Mazes/",
  name:"20 Small Mazes",
  type:"game",
},
{
  date:"August 26th",
  link:"https://store.steampowered.com/app/1675830/1000xRESIST/",
  name:"1000xRESIST",
  type:"game",
},
{
  date:"September 2nd",
  link:"https://store.steampowered.com/app/1395400/The_Corruption_Within/",
  name:"The Corruption Within",
  type:"game",
},
{
  date:"September 16th",
  link:"https://store.steampowered.com/app/2596420/Arranger_A_RolePuzzling_Adventure/",
  name:"Arranger",
  type:"game",
},
{
  date:"September 23rd",
  link:"https://seamus-conneely.itch.io/lost-among-the-starlit-wreckage",
  name:"Lost Among the Starlit Wreckage",
  type:"ttrpg",
},
{
  date:"September 30th",
  link:"https://store.steampowered.com/app/2763670/Arctic_Eggs/",
  name:"Arctic Eggs",
  type:"game",
}]

export default gameList

